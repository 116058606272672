@media only screen and (max-width: 600px) {
    .main-box-table{
        padding:0;
        margin:10px !important;
    
    }
    .main-box{
        padding:0;
        margin-bottom: 40px;
 
    
    }
    .logo{
        width: 40px;
    }
    /* .main-container{
        padding-top: 70px;
    } */
  }