@media (max-width: 600px) {
    .custom-paper {
      height: 100%; /* Set height to 100% for xs screen */
      max-height: 300px; /* Set max-height to 300px for xs screen */
    }

    .player-card{
      height: 350px;
    }
    .css-ml0n2i{
      padding-left: 0 !important;
      padding-right: 10px !important;
    }
  }
  