.login-form {
    color:white;
}


.login-page {
    padding:20px;
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(#37b6e8, #528EE0);
}

