.search-container {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
  
  }
  
  .search-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 2px;
    padding-left: 10px;
  }
  
  .search-icon {
    color: #999;
  }
  
  .search-input {
    border: none;
    padding: 8px;
    flex-grow: 1;
    font-size: 14px;
    outline: none;
    padding-left: 10px;
  }
  